import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/about-us',
		component: () => import('../views/About.vue'),
	},
	{
		path: '/team',
		component: () => import('../views/Team.vue'),
	},
	{
		path: '/services',
		component: () => import('../views/Services.vue'),
	},
	{
		path: '/alliances',
		component: () => import('../views/Alliances.vue'),
	},
	{
		path: '/contact-us',
		component: () => import('../views/Contact.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

export default router
