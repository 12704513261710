import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '../public/css/bootstrap.min.css';
import '../public/css/default.scss';
import '../public/css/styles.scss';
import '../public/css/app.scss'
import '../public/css/icomoon.css';

Vue.config.productionTip = false

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyBWpMPEdGVc0MTu5L5RawnJrR-IWXbKzYU",
//   authDomain: "s4l-consult-dev.firebaseapp.com",
//   projectId: "s4l-consult-dev",
//   storageBucket: "s4l-consult-dev.appspot.com",
//   messagingSenderId: "270661547999",
//   appId: "1:270661547999:web:3c153afcb4f81321fb766a",
//   measurementId: "G-302EBCK153"
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBT-MiFxaUxm6x76ijTwZ9-xeWJxy1Wsd8",
  authDomain: "s4l-consult.firebaseapp.com",
  projectId: "s4l-consult",
  storageBucket: "s4l-consult.appspot.com",
  messagingSenderId: "186899682486",
  appId: "1:186899682486:web:7aeed94977c4f633368217",
  measurementId: "G-YMNZ7PX45F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

import VueIzitoast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
Vue.use(VueIzitoast);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
