
<template>
    <div id="app">
        <AppHeader />
        <router-view />
        <AppFooter />
    </div>
</template>

<script>
export default {
    components: {
        AppHeader: () => import("@/components/Header.vue"),
        AppFooter: () => import("@/components/Footer.vue"),
    },
};
</script>
